@import 'animate.css';

@font-face {
    font-family: "Louis George Cafe";
    src: url("../fonts/Louis-George-Cafe.ttf");
}

@font-face {
    font-family: "Louis George Cafe Light";
    src: url("../fonts/Louis-George-Cafe-Light.ttf");
}

@font-face {
    font-family: "Zachery";
    src: url("../fonts/Zachery.otf");
}

@font-face {
    font-family: "Palatino Lynotype";
    src: url("../fonts/Palatino Linotype.ttf");
}

$color-orange : #9c4c1e;
$color-white : #FEFEE2;
$color-grey : #9D9D9C;
$max-width-value: 500px;

h1,
h2,
h3,
h4 {
    font-family: "Palatino Lynotype";
    user-select: none;
}

h1 {
    text-transform: uppercase;
    letter-spacing: 2px;
}

h3 {
    font-style: italic;
    color: #9D9D9C;
}

p {
    font-family: "Louis George Cafe";
    text-align: justify;
    letter-spacing: 1px;
}

.p-global {
    padding: 0 7% 0 7%;
}

