@import "./global.scss";

.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        &-video {
            top: 0;
            width: 100%;
            height: 80vh;
            background-color: $color-orange;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("../img/home/arbre1.jpg");
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
      

            &>img {
                height: 30%;
                animation: slideInUp;
                animation-duration: 1.5s;

                @media(max-width: 805px) {
                    height: 18%;
                }
            }
        }

        &-infos {
            display: flex;
            gap: 40px;
          padding: 7% !important;
            justify-content: center;
            align-items: center;

            @media(max-width: 1255px) {
                height: 100%;
                flex-direction: column;
                margin: 10% 0 10% 0;
            }

            &__content {
                width: 90%;
                @media(max-width: $max-width-value) {
                   width: unset;
                }
                h1{
                    margin-top: 0;
                }
                &-carousel{
                    width: 100%;
                }
            }
        }

        &-plus {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: $color-orange;
            padding: 2em 0;

            @media(max-width: $max-width-value) {
                height: 100%;
                padding: 10% 0 10% 0;
            }

            &-title {
                font-size: 4em;
                margin: 20px 0 0 0;
                color: #fff;
                text-align: center;

                @media(max-width: $max-width-value) {
                    font-size: 3em;
                }
            }

            &-p {
                color: #fff;
                padding: 30px 9vw 2em 9vw;
                text-align: center;
                font-size: 20px;

            }

            &__pictures {
                width: 30%;
                height: 75%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                @media(max-width: $max-width-value) {
                    width: 80%;
                }

                &-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 50px;

                    @media(max-width: $max-width-value) {
                        flex-direction: column;
                        padding-top: 5%;
                    }

                    :link {
                        text-decoration: none;
                    }
                }

                &__img {
                    width: 100%;
                    border-radius: 2%;
                    box-shadow: 8px 8px 19px -4px rgba(0, 0, 0, 0.38);
                    filter: grayscale(1);
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    -webkit-transition: .4s ease-in-out;
                    transition: .4s ease-in-out;

                    &:hover {
                        filter: none;
                        -webkit-transform: scale(1.05);
                        transform: scale(1.05);
                    }
                }

                &__text {
                    text-transform: none;
                    color: $color-white;
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 2px;
                        background-color: $color-white;
                        left: 50%;
                        bottom: -6px;
                        transition: all 0.4s ease-in-out;
                    }
                }

                &:hover &__text::after {
                    width: 100%;
                    left: 0;
                }


            }
        }
    }
}

.carousel__img {
    width: 100%;
    border-radius: 2%;
}