@import "./global.scss";

.gites {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &__intro {
        height: 50vh;
        width: 100%;
        background-image: url("../img/paysage/ocres1.jpg");
        background-attachment: fixed;
        background-size: cover;
        background-position-y: -17em;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        @media(max-width: $max-width-value) {
           height: 30vh;
        }

        &-container {
            height: 40%;
            width: 70%;
            background-color: #fff;
            padding: 4em;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            @media(max-width: $max-width-value) {
                width: 80%;
                height: 55%;
            }

        }

        &__title {
            &-1 {
                color: $color-orange;
                margin: 0;
                text-transform: none;

                @media(max-width: $max-width-value) {
                    font-size: 25px;
                }

            }

            &-2 {
                color: $color-grey;
padding: 20px 10em;
text-align: center;
color: #4D4D4D;
line-height: 32px;
                @media(max-width: $max-width-value) {
                    font-size: 20px;
                    padding: 20px 1em;
                }

            }
        }
    }

    &__items {
        background-color: $color-orange;
        width: 70%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        border-radius: 20px;
        color: #fff;
        box-shadow: 8px 8px 19px -4px rgb(0 0 0 / 38%);

        @media(max-width: $max-width-value) {
            width: 80%;
            flex-direction: column;
            height: fit-content;
            align-items: center;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            @media(max-width: $max-width-value) {
                flex-direction: column;
            }
        }

        &-container {
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;
            margin-top: 20em;
            padding: 0 0 4em 0;
            @media(max-width: 1420px) {
                margin-top: 30em;
                padding: 0 0 4em 0;
            }
            @media(max-width: $max-width-value) {
                width: 100%;
            }

        }

        h3 {
            color: #ffb58a;
            font-size: 28px;
        }

        &-img {

            background-size: cover;
            border-radius: 20px;
            width: 50%;
            overflow: hidden;
            background-repeat: no-repeat;

            &-content {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &-button {
            height: 35px;
            width: 200px;
            background-color: unset;
            border: solid 2px #fff;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            transition: .5s;
            font-weight: bold;
            text-transform: uppercase;

            &:hover {
                background-color: #fff;
                color: $color-orange;
            }

            &-close {
                height: 35px;
                width: 200px;
                background-color: unset;
                border: solid 2px $color-orange;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-orange;
                cursor: pointer;
                transition: .5s;
                font-weight: bold;
                text-transform: uppercase;
                @media(max-width: $max-width-value) {
                    margin-top: 10px;
                }
    
                &:hover {
                    background-color: $color-orange;
                    color: #fff;
                }

            }
        }

        &-infos {
            width: 70%;
            padding: 5em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media(max-width: $max-width-value) {
                padding: 10px 5px 30px 5px;
                align-items: center;
            }

            &-even {
                align-items: flex-end;

                @media(max-width: $max-width-value) {
                    align-items: center;
                }
            }
            div{
                height: 100%;
            }

            p{
                font-size: 18px;
                line-height: 1.5em;
            }

            h2{
                margin: 0;
                font-size: 47px;
            }
        }
    }
}

.Overlay {
    background-color: aqua;
}


.Modal {
    margin: 20px 64px 0 64px;
    animation: fadeInUp;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    background-color: white;
    padding: 5em;
    border-radius: 20px;
    box-shadow: 8px 8px 19px -4px rgb(0 0 0 / 38%);

    @media(max-width: $max-width-value) {
        margin: 0;
    }

    &__container {
        display: flex;
        width: 100%;
        height: 64vh;
        @media(max-width: $max-width-value) {
            flex-direction: column;
            overflow-y: scroll;
        }
    }

    &__section {
        width: 50%;
        @media(max-width: $max-width-value) {
            width: 100%;
           
        }
        &-text {
            padding-right: 10em;
            margin-bottom: 4em;
            line-height: 2em;
            overflow-y: scroll;
            @media(max-width: $max-width-value) {
                padding: 0;
                overflow-y: unset;
            }
            p{
                @media(max-width: $max-width-value) {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        &-img {
            overflow-y: scroll;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 50%;
            @media(max-width: $max-width-value) {
                overflow-y: unset;
                width: 100%;
            }
        }

        img {
            max-width: 45%;
            height: auto;
            margin: 10px;
            border-radius: 25px;
            box-shadow: 8px 8px 19px -4px rgb(0 0 0 / 38%);
            @media(max-width: $max-width-value) {
                max-width: 100%;
                margin-left: 0;
            }
        }
    }
}