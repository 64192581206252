@import "./global.scss";

.nav {
  background-color: #fff;
  color: $color-grey;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  height: 100px;

}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 3rem;
  font-family: "Zachery";
  text-transform: uppercase;
  font-size: 20px;
  animation: slideInDown;
  animation-duration: 1.5s;


}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav a,
.nav li {
  transition: 0.3s;

  ::before {
    content: "•";
    margin-right: 45px;
    color: #d9d9d9;
    cursor: default;
  }
}

.nav li.active {
  color: $color-orange;

  ::before {
    color: #d9d9d9;
  }
}

.nav li:hover,
.nav a:hover {
  color: #bf6937;

  ::before {
    color: #d9d9d9;
  }
}

.btn {
  display: none;
}

@media screen and (max-width: 805px) {
  .nav {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 100001;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 8px 8px 19px -4px rgb(0 0 0 / 38%);
    flex-direction: column;
  }

  .list {
    flex-direction: column;
    height: auto;
    padding: 1em 0 1em 10%;
    gap: 1em;
    animation: slideInDown;
    animation-duration: 0.5s;
  }

  .items {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    margin-right: 0px;
    padding: 20px 0;
  }

  .nav a,
  .nav li {
    ::before {
      content: "";
      margin-right: 0;

    }
  }

  .btn {
    display: block;
    height: 50px;
    width: 50px;
    margin: 15px;
    position: -webkit-sticky;
    position: fixed;
    right: 0;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background-color: $color-orange;

    &:active {
      background-color: #bf6937;
    }

    &-svg {
      font-size: 30px;
      color: #fff;

    }
  }
}