@import "./global.scss";

.footer {
    display: flex;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 805px) {
        flex-direction: column;
    }

    &__avis {
        margin-top: 16px;
    }

    &__container {
        height: 100%;
        width: 50%;
        border: 1px solid #f1f1f1;
        border-bottom: none;
        padding: 50px 100px 50px 100px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media screen and (max-width: 805px) {
            width: unset;
            height: fit-content;
            border-bottom: 1px solid #f1f1f1;
            padding: 50px 40px 50px 40px;
        }

        &__select-language {
            padding: 20px;
        }

        h2,
        p {
            text-align: center;
        }

        h2 {
            margin: 0;
        }

        p {
            line-height: 25px;
        }

    }

    &__maps {
        height: 100%;

        &>* {
            width: 100%;
            height: 400px;
            border: none;
            border-radius: 10px;
            margin-top: 16px;
        }
    }

    &__rs {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;

        &-button {
            background-color: $color-orange;
            border-radius: 50%;
            cursor: pointer;
            border: none;
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 23px;
                color: white;
            }
        }

    }
}